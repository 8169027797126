import React from 'react'
import Img from 'gatsby-image'
import SEO from '~/components/seo'
import styled from '@emotion/styled'

const ImageContainer = styled.div`
  margin-top: 6rem;
`

const IndexPage = ({ data }) => (
  <>
    <SEO title="Home" keywords={[`vintage`, `paintings`, `flowers`]} />
    {console.log(data)}
    <ImageContainer>
      <Img fluid={data.file.childImageSharp.fluid} />
    </ImageContainer>
  </>
)

export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
    file(name: { eq: "home-illustration" }) {
      id
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
